import React from 'react'

// GraphQL
import { JOB_DOWNLOAD_PHOTO_LIST, JOB_UNSTICK_PHOTO_PROCESSING } from '../../graphql/mutations'

// Plugins
import { saveAs } from 'file-saver'
import { Dropdown, Tooltip, message } from 'antd'
import { DownOutlined } from '@ant-design/icons'

// Components
import JobLink from './JobLink'
import JobEditDrawer from './JobEditDrawer'
import DrawerLink from '../shared/DrawerLink/DrawerLink'
import ButtonLink from '../shared/ButtonLink/ButtonLink'
import JobMarketingEmailsDrawer from './JobMarketingEmailsDrawer'

// Helpers / Hooks
import { useMutation } from '@apollo/react-hooks'
import { hasPermission } from '../../utilities/permissions'
import { useErrors, useGraphqlError } from '../../utilities/hooks'

const JobActionsDropdown = ({ job }) => {
  const canManageEntities = hasPermission('admin_manage_entities')
  const canQa = hasPermission('admin_qa')

  const [jobDownloadPhotoList, { data, loading, error }] = useMutation(JOB_DOWNLOAD_PHOTO_LIST)
  useErrors(data?.jobDownloadPhotoList?.errors)
  useGraphqlError(error)

  const [jobUnstickPhotoProcessing, { processingData, processingLoading, processingError }] = useMutation(JOB_UNSTICK_PHOTO_PROCESSING)
  useErrors(processingData?.jobUnstickPhotoProcessing?.errors)
  useGraphqlError(processingError)

  const onDownloadPhotoList = async () => {
    const params = { variables: { input: { id: job.id } } }
    const response = await jobDownloadPhotoList(params)
    if (response?.data?.jobDownloadPhotoList?.errors?.length === 0) {
      message.success('Downloading.')
      const blob = response?.data?.jobDownloadPhotoList?.file?.blob
      const filename = response?.data?.jobDownloadPhotoList?.file?.filename
      const fileContents = window.atob(blob)
      const newBlob = new Blob([fileContents], { type: 'text/plain;charset=utf-8' })
      saveAs(newBlob, filename)
    }
  }

  const onUnstickPhotoProcessing = async () => {
    const params = { variables: { input: { id: job.id } } }
    const response = await jobUnstickPhotoProcessing(params)

    const errors = response.data?.jobUnstickPhotoProcessing?.errors
    if (errors && errors.length > 0) return message.error(errors[0].message)

    message.success('Photo processing re-ran. Please confirm by checking the UI in studio.')
  }

  const items = [
    { key: '0', label: <JobLink job={job}>View</JobLink> },
    {
      key: '1',
      label: (
        <DrawerLink drawerElement={JobEditDrawer} childProps={{ job: job }} disabled={!canManageEntities}>
          <Tooltip title={canManageEntities ? null : 'You must request permission to be able to manage entities.'}>Edit Job</Tooltip>
        </DrawerLink>
      )
    },
    {
      key: '2',
      label: (
        <>
          {canQa ? (
            <DrawerLink drawerElement={JobMarketingEmailsDrawer} childProps={{ jobId: job.id }}>
              <span>Test Email Campaign</span>
            </DrawerLink>
          ) : null}
        </>
      )
    },
    {
      key: '3',
      label: (
        <ButtonLink
          name="Download Photo List"
          color="secondary"
          disabled={!canManageEntities}
          onClick={loading || !canManageEntities ? null : onDownloadPhotoList}
        >
          <Tooltip title={canManageEntities ? null : 'You must request permission to be able to manage entities.'}>
            {loading ? 'Downloading...' : 'Download Photo List'}
          </Tooltip>
        </ButtonLink>
      )
    },
    {
      key: '4',
      label: (
        <ButtonLink
          name="Fix stuck photo processing"
          color="secondary"
          disabled={processingLoading}
          onClick={processingLoading ? null : onUnstickPhotoProcessing}
        >
          <span>{processingLoading ? 'Fixing...' : 'Fix stuck photo processing'}</span>
        </ButtonLink>
      )
    }
  ]

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <span className="ant-dropdown-link">
        Actions &nbsp;
        <DownOutlined />
      </span>
    </Dropdown>
  )
}

export default JobActionsDropdown
