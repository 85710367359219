import { gql } from 'graphql-tag'
import {
  CUSTOMER_ATTRIBUTES,
  ERROR_ATTRIBUTES,
  FILE_ATTRIBUTES,
  GIFT_CARD_ATTRIBUTES,
  JOB_ATTRIBUTES,
  LAB_SHORT_ATTRIBUTES,
  ORDER_ATTRIBUTES,
  REFUND_GROUP_ATTRIBUTES,
  STUDIO_ATTRIBUTES,
  USER_ATTRIBUTES,
  SUPPORT_REQUEST_REPRINT_ATTRIBUTES,
  SUPPORT_REQUEST_SMS_CAMPAIGN_ATTRIBUTES,
  SUPPORT_REQUEST_EVENT_ATTRIBUTES
} from './fragments'

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      customer {
        ...customerAttributes
      }
      errors {
        ...errorAttributes
      }
    }
  }
  ${CUSTOMER_ATTRIBUTES}
  ${ERROR_ATTRIBUTES}
`

export const DESTROY_CUSTOMER = gql`
  mutation DestroyCustomer($input: DestroyCustomerInput!) {
    destroyCustomer(input: $input) {
      customer {
        ...customerAttributes
      }
      errors {
        ...errorAttributes
      }
    }
  }
  ${CUSTOMER_ATTRIBUTES}
  ${ERROR_ATTRIBUTES}
`

export const UPDATE_JOB = gql`
  mutation UpdateJob($input: UpdateJobInput!) {
    updateJob(input: $input) {
      job {
        ...jobAttributes
      }
      errors {
        ...errorAttributes
      }
    }
  }
  ${JOB_ATTRIBUTES}
  ${ERROR_ATTRIBUTES}
`

export const JOB_DOWNLOAD_PHOTO_LIST = gql`
  mutation JobDownloadPhotoList($input: DownloadPhotoListInput!) {
    jobDownloadPhotoList(input: $input) {
      file {
        ...fileAttributes
      }
      errors {
        ...errorAttributes
      }
    }
  }
  ${FILE_ATTRIBUTES}
  ${ERROR_ATTRIBUTES}
`

export const JOB_SEND_MARKETING_EMAIL = gql`
  mutation JobSendMarketingEmail($input: SendMarketingEmailInput!) {
    jobSendMarketingEmail(input: $input) {
      success
      errors {
        ...errorAttributes
      }
    }
  }
  ${ERROR_ATTRIBUTES}
`

export const JOB_UNSTICK_PHOTO_PROCESSING = gql`
  mutation UnstickPhotoProcessing($input: UnstickPhotoProcessingInput!) {
    jobUnstickPhotoProcessing(input: $input) {
      success
      errors {
        ...errorAttributes
      }
    }
  }
  ${ERROR_ATTRIBUTES}
`

export const ORDER_DOWNLOAD_FINAL_IMAGES_LIST = gql`
  mutation OrderDownloadFinalImagesList($input: DownloadFinalImagesListInput!) {
    orderDownloadFinalImagesList(input: $input) {
      file {
        ...fileAttributes
      }
      errors {
        ...errorAttributes
      }
    }
  }
  ${FILE_ATTRIBUTES}
  ${ERROR_ATTRIBUTES}
`

export const UPDATE_GIFT_CARD = gql`
  mutation UpdateGiftCard($input: UpdateGiftCardInput!) {
    updateGiftCard(input: $input) {
      giftCard {
        ...giftCardAttributes
      }
      errors {
        ...errorAttributes
      }
    }
  }
  ${GIFT_CARD_ATTRIBUTES}
  ${ERROR_ATTRIBUTES}
`

export const TRANSFER_GIFT_CARD = gql`
  mutation TransferGiftCard($input: TransferGiftCardInput!) {
    transferGiftCard(input: $input) {
      giftCard {
        ...giftCardAttributes
      }
      errors {
        ...errorAttributes
      }
    }
  }
  ${GIFT_CARD_ATTRIBUTES}
  ${ERROR_ATTRIBUTES}
`

export const ZERO_GIFT_CARDS = gql`
  mutation ($input: ZeroGiftCardsInput!) {
    zeroGiftCards(input: $input) {
      success
      errors {
        ...errorAttributes
      }
    }
  }
  ${ERROR_ATTRIBUTES}
`

export const UPDATE_ORDER = gql`
  mutation UpdateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      order {
        ...orderAttributes
      }
      errors {
        ...errorAttributes
      }
    }
  }
  ${ORDER_ATTRIBUTES}
  ${ERROR_ATTRIBUTES}
`

export const SEND_SHIP_ORDER = gql`
  mutation SendShipOrder($input: SendShipOrderInput!) {
    sendShipOrder(input: $input) {
      success
      errors {
        ...errorAttributes
      }
    }
  }
  ${ERROR_ATTRIBUTES}
`

export const UPDATE_POLICY = gql`
  mutation UpdateCompanyPolicy($input: UpdateCompanyPolicyInput!) {
    updateCompanyPolicy(input: $input) {
      companyPolicy {
        id
        name
        description
        key
        url
        userChangedAt
        customerChangedAt
      }
      errors {
        ...errorAttributes
      }
    }
  }
  ${ERROR_ATTRIBUTES}
`

export const CANCEL_ORDER = gql`
  mutation CancelOrder($input: CancelOrderInput!) {
    cancelOrder(input: $input) {
      order {
        ...orderAttributes
      }
      errors {
        ...errorAttributes
      }
    }
  }
  ${ORDER_ATTRIBUTES}
  ${ERROR_ATTRIBUTES}
`

export const DELETE_ORDER = gql`
  mutation DeleteOrder($input: DeleteOrderInput!) {
    deleteOrder(input: $input) {
      order {
        ...orderAttributes
      }
      errors {
        ...errorAttributes
      }
    }
  }
  ${ORDER_ATTRIBUTES}
  ${ERROR_ATTRIBUTES}
`

export const CREATE_REFUND_GROUP = gql`
  mutation CreateRefundGroup($input: CreateRefundGroupInput!) {
    createRefundGroup(input: $input) {
      refundGroup {
        ...refundGroupAttributes
      }
      errors {
        ...errorAttributes
      }
    }
  }
  ${REFUND_GROUP_ATTRIBUTES}
  ${ERROR_ATTRIBUTES}
`

export const UPDATE_REFUND_GROUP = gql`
  mutation UpdateRefundGroup($input: UpdateRefundGroupInput!) {
    updateRefundGroup(input: $input) {
      refundGroup {
        ...refundGroupAttributes
      }
      errors {
        ...errorAttributes
      }
    }
  }
  ${REFUND_GROUP_ATTRIBUTES}
  ${ERROR_ATTRIBUTES}
`

export const UPDATE_STUDIO = gql`
  mutation UpdateStudio($input: UpdateStudioInput!) {
    updateStudio(input: $input) {
      studio {
        ...studioAttributes
      }
      errors {
        ...errorAttributes
      }
    }
  }
  ${STUDIO_ATTRIBUTES}
  ${ERROR_ATTRIBUTES}
`

export const ARCHIVE_STUDIO = gql`
  mutation ArchiveStudio($input: ArchiveStudioInput!) {
    archiveStudio(input: $input) {
      success
      errors {
        ...errorAttributes
      }
    }
  }
  ${ERROR_ATTRIBUTES}
`

export const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        ...userAttributes
      }
      errors {
        ...errorAttributes
      }
    }
  }
  ${USER_ATTRIBUTES}
  ${ERROR_ATTRIBUTES}
`

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...userAttributes
      }
      errors {
        ...errorAttributes
      }
    }
  }
  ${USER_ATTRIBUTES}
  ${ERROR_ATTRIBUTES}
`

export const USER_SIGN_IN = gql`
  mutation UserSignIn($input: UserSignInInput!) {
    userSignIn(input: $input) {
      jwt
      user {
        ...userAttributes
      }
      errors {
        ...errorAttributes
      }
    }
  }
  ${USER_ATTRIBUTES}
  ${ERROR_ATTRIBUTES}
`

export const RESET_PASSWORD_LINK = gql`
  mutation ResetPasswordLink($input: ResetPasswordLinkInput!) {
    resetPasswordLink(input: $input) {
      resetPasswordUrl
      errors {
        ...errorAttributes
      }
    }
  }
  ${ERROR_ATTRIBUTES}
`

export const SEND_ORDER_TO_LAB = gql`
  mutation SendOrderToLab($input: SendToLabInput!) {
    sendOrderToLab(input: $input) {
      order {
        id
        num
        lab {
          ...labShortAttributes
        }
        sentOrders {
          id
          sentToPrinter
          createdAt
        }
      }
      errors {
        ...errorAttributes
      }
    }
  }
  ${LAB_SHORT_ATTRIBUTES}
  ${ERROR_ATTRIBUTES}
`

export const ORDER_RESEND_RECEIPT = gql`
  mutation OrderResendReceipt($input: ResendReceiptInput!) {
    orderResendReceipt(input: $input) {
      errors {
        ...errorAttributes
      }
    }
  }
  ${ERROR_ATTRIBUTES}
`

export const ORDER_RESEND_DD_RELEASE = gql`
  mutation OrderResendReceipt($input: ResendDdReleaseInput!) {
    orderResendDdRelease(input: $input) {
      errors {
        ...errorAttributes
      }
    }
  }
  ${ERROR_ATTRIBUTES}
`

export const UPDATE_SMS_CAMPAIGN_REQUEST = gql`
  mutation UpdateSmsCampaignRequest($input: UpdateSmsCampaignRequestInput!) {
    updateSmsCampaignRequest(input: $input) {
      supportRequest {
        ...supportRequestSmsCampaignAttributes
      }
      supportRequests {
        ...supportRequestSmsCampaignAttributes
      }
      errors {
        ...errorAttributes
      }
    }
  }
  ${SUPPORT_REQUEST_SMS_CAMPAIGN_ATTRIBUTES}
  ${ERROR_ATTRIBUTES}
`
export const UPDATE_REPRINT_REQUEST = gql`
  mutation UpdateReprintRequest($input: UpdateReprintRequestInput!) {
    updateReprintRequest(input: $input) {
      supportRequest {
        ...supportRequestReprintAttributes
      }
      supportRequests {
        ...supportRequestReprintAttributes
      }
      errors {
        ...errorAttributes
      }
    }
  }
  ${SUPPORT_REQUEST_REPRINT_ATTRIBUTES}
  ${ERROR_ATTRIBUTES}
`

export const CREATE_SUPPORT_REQUEST_EVENT = gql`
  mutation CreateSupportRequestEvent($input: CreateSupportRequestEventInput!) {
    createSupportRequestEvent(input: $input) {
      supportRequestEvent {
        ...supportRequestEventAttributes
      }
      errors {
        ...errorAttributes
      }
    }
  }
  ${SUPPORT_REQUEST_EVENT_ATTRIBUTES}
  ${ERROR_ATTRIBUTES}
`

export const EXPORT_SUPPORT_REQUESTS = gql`
  mutation ExportSupportRequests($input: ExportSupportRequestsInput!) {
    exportSupportRequests(input: $input) {
      data
      errors {
        ...errorAttributes
      }
    }
  }
  ${ERROR_ATTRIBUTES}
`

export const DUPLICATE_PRICE_SHEET = gql`
  mutation DuplicatePriceSheet($input: DuplicatePriceSheetInput!) {
    duplicatePriceSheet(input: $input) {
      priceSheet {
        id
        name
      }
      errors {
        ...errorAttributes
      }
    }
  }
  ${ERROR_ATTRIBUTES}
`
