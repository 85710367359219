import React from 'react'
import { Link } from 'react-router-dom'
import { Descriptions, Button, Tooltip } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import useObjectQuery from '../../api/useObjectQuery'
import { dateFormatter, phoneFormatter, emailFormatter } from '../../utilities/formatters'
import { UserLink } from '../shared/Links'
import Drawer from '../shared/Drawer'
import Copy from '../shared/Copy'
import StudioEditDrawer from './StudioEditDrawer'
import DrawerLink from '../shared/DrawerLink/DrawerLink'
import { STUDIO_QUERY } from '../../graphql/queries'
import { hasPermission } from '../../utilities/permissions'
const { Item } = Descriptions

const StudioDrawer = (props) => {
  const { id } = props
  const { object: studio } = useObjectQuery('studio', STUDIO_QUERY, id)
  if (studio == null) return <></>

  const { primaryLab } = studio
  const canManageEntities = hasPermission('admin_manage_entities')
  return (
    <Drawer {...props} width={600}>
      <Descriptions column={1} title={`Studio: ${studio.name}`} bordered>
        <Item label="ID">
          <Copy text={studio.id}>{studio.id}</Copy>
        </Item>
        <Item label="Owner">
          <UserLink user={studio.owner} />
        </Item>
        <Item label="Phone">{phoneFormatter(studio.phone)}</Item>
        <Item label="Email">{emailFormatter(studio.email)}</Item>
        <Item label="Studio Identifier">{studio.studioSlug}</Item>
        <Item label="Primary Lab">{primaryLab && <Link to={`/labs/${primaryLab.id}`}>{studio.primaryLab.name}</Link>}</Item>
        <Item label="PD Fee">{studio.pdFee}%</Item>
        <Item label="Created At">{dateFormatter(studio.createdAt)}</Item>
        <Item label="Stripe Migration Status">{studio.stripeMigrationStatus}</Item>
        <Item label="Verification Status">{studio.verificationStatus ?? 'n/a'}</Item>
        {studio.certifyAt && (
          <>
            <Item label="Certify At">{dateFormatter(studio.certifyAt, true)}</Item>
          </>
        )}
      </Descriptions>
      <DrawerLink className="pt-5 justify-end" drawerElement={StudioEditDrawer} childProps={{ studio: studio }} disabled={!canManageEntities}>
        <Tooltip title={canManageEntities ? null : 'You must request permissions to be able to manage entities.'}>
          <Button disabled={!canManageEntities} type="primary" icon={<EditOutlined />}>
            Edit
          </Button>
        </Tooltip>
      </DrawerLink>
    </Drawer>
  )
}

export default StudioDrawer
