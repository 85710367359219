export const timeZones = {
  "Alaska": "America/Juneau",
  "America/Adak": "America/Adak",
  "America/Anchorage": "America/Anchorage",
  "America/Boise": "America/Boise",
  "America/Detroit": "America/Detroit",
  "America/Indiana/Knox": "America/Indiana/Knox",
  "America/Indiana/Marengo": "America/Indiana/Marengo",
  "America/Indiana/Petersburg": "America/Indiana/Petersburg",
  "America/Indiana/Tell_City": "America/Indiana/Tell_City",
  "America/Indiana/Vevay": "America/Indiana/Vevay",
  "America/Indiana/Vincennes": "America/Indiana/Vincennes",
  "America/Indiana/Winamac": "America/Indiana/Winamac",
  "America/Kentucky/Louisville": "America/Kentucky/Louisville",
  "America/Kentucky/Monticello": "America/Kentucky/Monticello",
  "America/Menominee": "America/Menominee",
  "America/Metlakatla": "America/Metlakatla",
  "America/Nome": "America/Nome",
  "America/North_Dakota/Beulah": "America/North_Dakota/Beulah",
  "America/North_Dakota/Center": "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem": "America/North_Dakota/New_Salem",
  "America/Sitka": "America/Sitka",
  "America/Yakutat": "America/Yakutat",
  "Arizona": "America/Phoenix",
  "Central Time (US & Canada)": "America/Chicago",
  "Eastern Time (US & Canada)": "America/New_York",
  "Hawaii": "Pacific/Honolulu",
  "Indiana (East)": "America/Indiana/Indianapolis",
  "Mountain Time (US & Canada)": "America/Denver",
  "Pacific Time (US & Canada)": "America/Los_Angeles"
}
